import React, { lazy, Suspense } from 'react'
import { createBrowserRouter, createRoutesFromElements, Link, Navigate, Route } from 'react-router-dom'

// Skeleton
import LoadingSkele from './components/ui-skeleton/LoadingSkele'

// Website routes
import PanelHeader from './components/panel/common/panelHeader/PanelHeader'
import Logout from './components/panel/pages/settings/Logout'
import AppLogout from './AppLogout'
const Login = lazy(() => import('./components/website/auth/login/Login'))
const SignUp = lazy(() => import('./components/website/auth/signup/SignUp'))

// Panel
const Profile = lazy(() => import('./components/panel/pages/settings/Profile'))
const Dashboard = lazy(() => import('./components/panel/pages/dashboard/Dashboard'))
const Orders = lazy(() => import('./components/panel/pages/orders/Orders'))
const Account = lazy(() => import('./components/panel/pages/account/Account'))
const AccountDetails = lazy(() => import('./components/panel/pages/settings/AccountDetails'))
const AddMoney = lazy(() => import('./components/panel/pages/account/AddMoney'))
const WithdrawMoney = lazy(() => import('./components/panel/pages/account/WithdrawMoney'))

// assets
const title = <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>Bharat Capital</Link>
const basicImg = require('./assets/user_default.png')

const user = JSON.stringify({
  fullName: '',
  contactNo: '',
  email: '',
  aadharNo: '',
  panNo: '',
  accountNo: '',
  ifsc: '',
  profile: basicImg,
  username: '',
  password: '',
  userID: null,
  authenticated: false
})

if (localStorage.user === undefined) {
  localStorage.setItem('user', user)
}

const getId = (index) => {
  const id = window.location.pathname.split('/')
  const user = JSON.parse(localStorage.user)
  if (user !== undefined) {
    if (user.userID !== null && user.userID.toString() === id[index]) {
      return true
    } else {
      return false
    }
  } else return false
}

const PrivateRoute = ({ component, index }) => {
  return getId(index) ? component : (
    <>
      {alert('You are not authrized!')}
      <Navigate to="/" />
    </>
  )
}

const App = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path='/' element={
        <Suspense fallback={<LoadingSkele styles={{
          marginTop: 0
        }} />}>
          <Login title={title} />
        </Suspense>
      } />
      <Route path='/signup' element={
        <Suspense fallback={<LoadingSkele styles={{
          marginTop: 0
        }} />}>
          <SignUp title={title} />
        </Suspense>
      } />

      {/* Private Routes */}

      {/* Dashboard Route Start */}
      <Route
        path='/dashboard/:userID'
        element={<PrivateRoute component={
          <AppLogout>
            <PanelHeader title={title} />
            <Suspense fallback={<LoadingSkele styles={{
              marginTop: 0,
            }} />}>
              <Dashboard title={title} />
            </Suspense>
          </AppLogout>
        } index={2} />} />
      {/* Dashboard Route End */}

      {/* User Routes Start */}
      <Route path='/user/profile/:userID' element={
        <PrivateRoute component={
          <AppLogout>
            <PanelHeader title={title} />
            <Suspense fallback={<LoadingSkele styles={{
              marginTop: 0,
            }} />}>
              <Profile title={title} />
            </Suspense>
          </AppLogout>
        } index={3} />
      } />

      <Route path='/user/account-details/:userID' element={
        <PrivateRoute component={
          <AppLogout>
            <PanelHeader title={title} />
            <Suspense fallback={<LoadingSkele styles={{
              marginTop: 0,
            }} />}>
              <AccountDetails title={title} />
            </Suspense>
          </AppLogout>
        } index={3} />
      } />

      <Route path='/user/add-money/:userID' element={
        <PrivateRoute component={
          <AppLogout>
            <PanelHeader title={title} />
            <Suspense fallback={<LoadingSkele styles={{
              marginTop: 0,
            }} />}>
              <AddMoney title={title} />
            </Suspense>
          </AppLogout>
        } index={3} />
      } />

      <Route path='/user/withdraw-money/:userID' element={
        <PrivateRoute component={
          <AppLogout>
            <PanelHeader title={title} />
            <Suspense fallback={<LoadingSkele styles={{
              marginTop: 0,
            }} />}>
              <WithdrawMoney title={title} />
            </Suspense>
          </AppLogout>
        } index={3} />
      } />

      <Route path='/user/logout/:userID' element={<Logout />} />
      {/* User Routes End */}

      {/* Account Route Start */}
      <Route path='/account/:userID' element={
        <PrivateRoute component={
          <AppLogout>
            <PanelHeader title={title} />
            <Suspense fallback={<LoadingSkele styles={{
              marginTop: 0,
            }} />}>
              <Account title={title} />
            </Suspense>
          </AppLogout>
        } index={2} />
      } />
      {/* Account Route End */}

      {/* Orders Route Start */}
      <Route path='/orders/:userID' element={
        <PrivateRoute component={
          <AppLogout>
            <PanelHeader title={title} />
            <Suspense fallback={<LoadingSkele styles={{
              marginTop: 0,
            }} />}>
              <Orders title={title} />
            </Suspense>
          </AppLogout>
        } index={2} />
      } />
      {/* Orders Route End */}
    </React.Fragment>
  )
)

export default App;
