import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// MUI Components
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import AppBar from '@mui/material/AppBar'
import Tooltip from '@mui/material/Tooltip'
import Toolbar from '@mui/material/Toolbar'
import MenuItem from '@mui/material/MenuItem'
import HomeIcon from '@mui/icons-material/Home'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import BottomNavigation from '@mui/material/BottomNavigation'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Poppins",
        }
    },
})

let settings = [], options = []

const PanelHeader = ({ title }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.user))
    const [anchorElUser, setAnchorElUser] = React.useState(null)
    useEffect(() => {
        setUser(JSON.parse(localStorage.user))

        if (user !== null || user !== undefined) {
            settings = [
                {
                    title: 'Profile',
                    path: `/user/profile/${user.userID.toString()}`
                },
                {
                    title: 'Account Details',
                    path: `/user/account-details/${user.userID.toString()}`
                },
                {
                    title: 'Logout',
                    path: `/logout/${user.userID.toString()}`
                }
            ]

            options = [
                {
                    id: 50,
                    title: 'HOME',
                    path: `/dashboard/${user.userID.toString()}`,
                    logo: HomeIcon,
                },
                {
                    id: 51,
                    title: 'WATCHLIST',
                    link: `https://in.tradingview.com/markets/stocks-india/market-movers-all-stocks/`,
                    logo: FormatListBulletedIcon,
                },
                {
                    id: 52,
                    title: 'ORDERS',
                    path: `/orders/${user.userID.toString()}`,
                    logo: BorderColorIcon,
                },
                {
                    id: 53,
                    title: 'ACCOUNT',
                    path: `/account/${user.userID.toString()}`,
                    logo: AccountCircleIcon,
                },
            ]
        }

        return
    }, [])

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', marginBottom: 7, paddingInline: 10 }}>
                <AppBar component="nav">
                    <Toolbar>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: {
                                    sm: 'block'
                                },
                                color: '#000',
                            }}
                        >
                            {title}
                        </Typography>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="User" src={user.profile} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting, index) => (
                                    <MenuItem key={index} onClick={handleCloseUserMenu}>
                                        <Typography
                                            component={'a'}
                                            href={setting.path}
                                            textAlign="center"
                                            sx={{
                                                textDecoration: 'none',
                                                color: 'rgba(0, 0, 0, 0.87)',
                                            }}
                                        >{setting.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>

                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={24}>
                        <BottomNavigation
                            showLabels
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                fontFamily: 'Poppins',
                                py: 1,
                            }}>
                            {/* Home */}
                            <BottomNavigationAction
                                key={50}
                                sx={{
                                    fontWeight: 'bold',
                                    '& span': {
                                        fontFamily: 'Poppins',
                                    }
                                }}
                                label={'HOME'}
                                component={Link}
                                to={`/dashboard/${user.userID.toString()}`}
                                icon={<HomeIcon />}
                            />
                            {/* WATCHLIST */}
                            <BottomNavigationAction
                                key={51}
                                sx={{
                                    fontWeight: 'bold',
                                    '& span': {
                                        fontFamily: 'Poppins',
                                    }
                                }}
                                label={'WATCHLIST'}
                                component={Link}
                                onClick={
                                    () => {
                                        window.open('https://in.tradingview.com/markets/stocks-india/market-movers-all-stocks/', '__blank')
                                    }
                                }
                                icon={<FormatListBulletedIcon />}
                            />
                            {/* ORDERS */}
                            <BottomNavigationAction
                                key={52}
                                sx={{
                                    fontWeight: 'bold',
                                    '& span': {
                                        fontFamily: 'Poppins',
                                    }
                                }}
                                label={'ORDERS'}
                                component={Link}
                                to={`/orders/${user.userID.toString()}`}
                                icon={<BorderColorIcon />}
                            />
                            {/* ACCOUNT */}
                            <BottomNavigationAction
                                key={53}
                                sx={{
                                    fontWeight: 'bold',
                                    '& span': {
                                        fontFamily: 'Poppins',
                                    }
                                }}
                                label={'ACCOUNT'}
                                component={Link}
                                to={`/account/${user.userID.toString()}`}
                                icon={<AccountCircleIcon />}
                            />
                        </BottomNavigation>
                    </Paper>
                </AppBar>
            </Box>
        </ThemeProvider>
    )
}

export default PanelHeader